import {
  IfacPositionHelpers,
} from '@ifac/ui';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Triennium',
      autoHeight: true,
      sort: 'desc',
      sortIndex: 0,
      sortable: false,
      comparator: (valueA, valueB) => {
        const positionsA = valueA.positions.sort((a, b) => b.triennium.from - a.triennium.from);
        const positionsB = valueB.positions.sort((a, b) => b.triennium.from - a.triennium.from);

        const triennialNumberA = positionsA?.[0].triennium.number;
        const triennialNumberB = positionsB?.[0].triennium.number;

        if (triennialNumberA === triennialNumberB) return 0;
        return (triennialNumberA > triennialNumberB) ? 1 : -1;
      },
      valueGetter: (params) => params.data,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.from - a.triennium.from);

        return positions
          .map((p) => `<div>${p.triennium.from} - ${p.triennium.to}</div>`)
          .join('');
      },
    },
    {
      sort: 'asc',
      sortIndex: 1,
      sortable: false,
      headerName: 'Full Name',
      flex: 1,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => {
        if (valueA.surname === valueB.surname) return 0;
        return (valueA.surname > valueB.surname) ? 1 : -1;
      },
    },
    {
      headerName: 'Citation',
      flex: 2,
      sortable: false,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.from - a.triennium.from);

        return positions
          .map((p) => `
          <div style="white-space: normal;word-break: break-word;line-height: normal;padding:10px 0">
            ${IfacPositionHelpers.parseNotes(p.notes)}
          </div>
          `)
          .join('<hr style="margin: 0">');
      },
    },
  ];
}
